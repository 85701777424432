<template>
    <!-- card -->
    <div class="bg-light-gray p-5 rounded-2xl">
      <!-- card header -->
      <div v-if="title" class="text-xl font-bold py-2">{{title}}</div>
      <!-- card content -->
      <div class="border-2 border-white p-3 py-5 rounded-2xl flex justify-center">
        <slot />
      </div>
      <!-- card footer -->
      <div v-if="!hideFooter" class="flex space-x-2 py-2 h-16 text-lg">
        <n-button v-if="!hideCancel" color="danger" @click="$emit('cancel')">取消</n-button>
        <n-button @click="$emit('submit')">確認</n-button>
      </div>
    </div>

</template>

<script>
import NButton from "./NButton.vue"
export default {
 props:{
    title: String,
    hideFooter: Boolean,
    hideCancel: Boolean,
 },
 components:{
   NButton,
 }
}
</script>

<style>

</style>