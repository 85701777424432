import { auth, db } from '@/firebase'

export const authModule = {
	state: () => ({
		authState: false,
		currentUser: null,
	}),

	getters: {
		authState: state => {
			return state.authState
		},

		currentUser: state => {
			return state.currentUser
		},
	},

	mutations: {
		'nube/SET_AUTH_STATE': (state, { authState }) => {
			state.authState = authState
		},

		'nube/SET_CURRENT_USER': (state, { user }) => {
			state.currentUser = user
		},
	},

	actions: {
		supportSignInWihPinNumber: async ({ commit }, { pinNumber }) => {
			const pinNumbers = ['970630', '960107', '931220', '851008']
			if (!pinNumbers.includes(pinNumber)) throw { message: 'NubeTech: Error (auth/invalid-pin-number).' }
			commit('nube/SET_AUTH_STATE', { authState: true })
			return true
		},

		staffSignInWithEmailAndPassword: async ({ commit }, { emailAddress, password }) => {
			const userCredential = await auth.signInWithEmailAndPassword(emailAddress, password)
			const docRef = db.collection('staffs').doc(userCredential.user.uid)
			const docSnap = await docRef.get()
			if (!docSnap.exists) throw { message: 'NubeTech: Error (auth/staff-not-found).' }
			const staff = docSnap.data()
			if (staff.status !== 'ACTIVE') throw { message: 'NubeTech: Error (auth/staff-invalid-status).' }
			commit('nube/SET_AUTH_STATE', { authState: true })
			commit('nube/SET_CURRENT_USER', { user: staff })
			return staff
		},

		staffSignInWithPinNumber: async ({ commit, getters }, { pinNumber }) => {
			const staff = getters.staffs.find(staff => staff.pinNumber === pinNumber)
			if (!staff) throw { message: 'NubeTech: Error (auth/staff-not-found).' }
			if (staff.status !== 'ACTIVE') throw { message: 'NubeTech: Error (auth/staff-invalid-status).' }
			commit('nube/SET_AUTH_STATE', { authState: true })
			commit('nube/SET_CURRENT_USER', { user: staff })
			return staff
		},

		signOut: async ({ commit }) => {
			commit('nube/SET_AUTH_STATE', { authState: false })
			commit('nube/SET_CURRENT_USER', { user: null })
			return true
		},
	},
}
