<template>
    <div>
        <div class="z-20 absolute top-0 left-0 w-full h-screen bg-black bg-opacity-50"></div>
        <div class="z-20 absolute top-0 left-0 w-full bg-transparent h-full flex justify-center items-center">
            <div class="flex flex-col space-y-5 w-3/4 bg-light-gray rounded-xl text-secondary p-5">
                <p class="text-xl text-left">{{ title }}</p>
                <div class="border-2 border-white rounded-2xl p-5 h-36 flex items-center justify-center text-3xl text-primary">
                    {{content}}
                </div>
                <div v-if="!hiddenFooter" class="w-full flex space-x-4">
                    <n-button color="danger" @click="$emit('close')">取消</n-button>
                    <n-button @click="$emit('click')">確認</n-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['title','content','hiddenFooter'],
}
</script>

<style></style>
