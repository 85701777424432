import { firestoreAction } from 'vuexfire'
import { db, functions } from '@/firebase'

export const saleOrderModule = {
	state: () => ({
		saleOrders: [],
		currentSaleOrder: null,
	}),

	getters: {
		saleOrders: state => {
			return state.saleOrders
		},

		saleOrder: state => id => {
			return state.saleOrders.find(saleOrder => saleOrder.id === id)
		},

		currentSaleOrder: state => {
			return state.currentSaleOrder
		},
	},

	actions: {
		bindSaleOrders: firestoreAction(async ({ bindFirestoreRef }, { shopId = null, startCounter = null, endCounter = null } = {}) => {
			let queryRef = db.collection('saleOrders').orderBy('counter')
			if (shopId) queryRef = queryRef.where('shop.id', '==', shopId)
			if (startCounter) queryRef = queryRef.where('counter', '>=', startCounter)
			if (endCounter) queryRef = queryRef.where('counter', '<=', endCounter)
			return bindFirestoreRef('saleOrders', queryRef)
		}),

		unbindSaleOrders: firestoreAction(async ({ unbindFirestoreRef }) => {
			return unbindFirestoreRef('saleOrders')
		}),

		createSaleOrder: async ({ getters }, { cart }) => {
			const createSaleOrder = functions.httpsCallable('createSaleOrder')
			const result = await createSaleOrder({ merchantId: getters.currentMerchant.id, shopId: getters.currentShop.id, cart })
			return result.data
		},

		updateSaleOrder: async (store, { saleOrder, cart }) => {
			const updateSaleOrder = functions.httpsCallable('updateSaleOrder')
			const result = await updateSaleOrder({ saleOrder, cart })
			return result.data
		},

		updateSaleOrderPax: async (store, { saleOrder, pax }) => {
			const updateSaleOrderPax = functions.httpsCallable('updateSaleOrderPax')
			const result = await updateSaleOrderPax({ saleOrder, pax })
			return result.data
		},

		updateSaleOrderPayment: async (store, { saleOrder, payment }) => {
			const updateSaleOrderPayment = functions.httpsCallable('updateSaleOrderPayment')
			const result = await updateSaleOrderPayment({ saleOrder, payment })
			return result.data
		},

		updateSaleOrderProduct: async (store, { saleOrder, index, product }) => {
			const updateSaleOrderProduct = functions.httpsCallable('updateSaleOrderProduct')
			const result = await updateSaleOrderProduct({ saleOrder, index, product })
			return result.data
		},

		updateSaleOrderStatus: async (store, { saleOrder, status }) => {
			const updateSaleOrderStatus = functions.httpsCallable('updateSaleOrderStatus')
			const result = await updateSaleOrderStatus({ saleOrder, status })
			return result.data
		},

		removeSaleOrderProduct: async (store, { saleOrder, index }) => {
			const removeSaleOrderProduct = functions.httpsCallable('removeSaleOrderProduct')
			const result = await removeSaleOrderProduct({ saleOrder, index })
			return result.data
		},

		bindCurrentSaleOrder: firestoreAction(async ({ bindFirestoreRef }, { id }) => {
			let queryRef = db.collection('saleOrders').doc(id)
			return bindFirestoreRef('currentSaleOrder', queryRef)
		}),

		unbindCurrentSaleOrder: firestoreAction(async ({ unbindFirestoreRef }) => {
			return unbindFirestoreRef('currentSaleOrder')
		}),
	},
}
