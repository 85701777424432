<template>
    <button class="px-5 rounded-2xl cursor-not-allowed flex items-center" 
	:class="[
		classNameMap[variant][color],
		classNameMap[size],
		between ? classNameMap['between']: classNameMap['center']
	]" 
	@click="$emit('click')">
		<font-awesome-icon v-if="leftIcon" :icon="leftIcon" class="p-2" />
		<slot />
		<font-awesome-icon v-if="rightIcon" :icon="rightIcon" class="p-2" />
    </button>
</template>

<script>
export default {
	name: 'n-button',
	props:
	{
		between: Boolean,
		leftIcon: String,
		rightIcon: String,
		variant:{
			default: 'fill',
		},
		size:{
			default: 'block',
		},
		color: {
			default: 'primary'		
		}
	},
	data() {
		return {
			classNameMap:{
				fill: {
					primary: 'bg-primary text-white',
					secondary: 'bg-secondary text-white',
					danger: 'bg-danger text-white',
					warning: 'bg-warning text-white',
					success: 'bg-success text-white',
					light: 'bg-white text-secondary',
					muted: 'bg-light-gray text-black',
					dark: 'bg-black text-white', 
					disabled: 'bg-gray text-white',
				},
				outline: {
					primary: 'border-2 border-primary text-primary bg-white',
					danger: 'border-2 border-danger text-danger bg-white',
					warning: 'border-2 border-warning text-warning bg-white',
					success: 'border-2 border-success text-success bg-white',
					light: 'border-2 border-white text-primary',
					muted: 'border-2 border-muted text-muted bg-white',
				},
				block: 'w-full py-2',
				center: 'justify-center space-x-5',
				between: 'justify-between',
				sm:'w-36 text-sm py-2',
				lg:'w-60 h-32 text-4xl py-2 rounded-3xl'
			}
		}
	},
}
</script>

<style></style>
