export function Cart() {
	return {
		method: 'PICKUP',
		products: [],
		productTotal: 0,
	}
}

export function CartProductItem(extra, item) {
	return {
		extraId: extra.id,
		name: item.name,
		price: item.price,
	}
}

export function CartProduct(product, items, quantity, total) {
	return {
		id: product.id,
		name: product.name,
		price: product.price,
		items: items,
		quantity: quantity,
		total: total,
		status: 'OPEN',
	}
}
