import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: 'activate'
  },
  {
    path: '/signage',
    component: () => import('@/views/Signage.vue')
  },
  {
    path: '/activate',
    component: () => import('@/views/Activate.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
