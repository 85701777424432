import { firestoreAction } from 'vuexfire'
import { db, functions } from '@/firebase'

export const areaModule = {
	state: () => ({
		areas: [],
	}),

	getters: {
		areas: state => {
			return state.areas
		},

		area: state => id => {
			return state.areas.find(area => area.id === id)
		},
	},

	actions: {
		bindAreas: firestoreAction(async ({ bindFirestoreRef }, { merchantId = null, shopId = null } = {}) => {
			let queryRef = db.collection('areas')
			if (merchantId) queryRef = queryRef.where('merchantId', '==', merchantId)
			if (shopId) queryRef = queryRef.where('shopId', '==', shopId)
			return bindFirestoreRef('areas', queryRef)
		}),

		unbindAreas: firestoreAction(async ({ unbindFirestoreRef }) => {
			return unbindFirestoreRef('areas')
		}),

		createArea: async (store, { area }) => {
			const createArea = functions.httpsCallable('createArea')
			const result = await createArea({ area })
			return result.data
		},

		updateArea: async (store, { area }) => {
			const updateArea = functions.httpsCallable('updateArea')
			const result = await updateArea({ area })
			return result.data
		},

		assignTable: async ({ getters }, { areaId, tables, pax }) => {
			const assignTable = functions.httpsCallable('assignTable')
			const result = await assignTable({ merchantId: getters.currentMerchant.id, shopId: getters.currentShop.id, areaId, tables, pax })
			return result.data
		},

		transferTable: async ({ getters }, { areaId, table, targetAreaId, targetTable }) => {
			const transferTable = functions.httpsCallable('transferTable')
			const result = await transferTable({ merchantId: getters.currentMerchant.id, shopId: getters.currentShop.id, areaId, table, targetAreaId, targetTable })
			return result.data
		},

		cleanTable: async ({ getters }, { areaId, table }) => {
			const cleanTable = functions.httpsCallable('cleanTable')
			const result = await cleanTable({ merchantId: getters.currentMerchant.id, shopId: getters.currentShop.id, areaId, table })
			return result.data
		},
	},
}
