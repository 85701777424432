import { firestoreAction } from 'vuexfire'
import { db, functions } from '@/firebase'

export const staffModule = {
	state: () => ({
		staffs: [],
	}),

	getters: {
		staffs: state => {
			return state.staffs
		},

		staff: state => id => {
			return state.staffs.find(staff => staff.id === id)
		},
	},

	actions: {
		bindStaffs: firestoreAction(async ({ bindFirestoreRef }, { merchantId = null, shopId = null } = {}) => {
			let queryRef = db.collection('staffs')
			if (merchantId) queryRef = queryRef.where('merchantId', '==', merchantId)
			if (shopId) queryRef = queryRef.where('shopIds', 'array-contains', shopId)
			return bindFirestoreRef('staffs', queryRef)
		}),

		unbindStaffs: firestoreAction(async ({ unbindFirestoreRef }) => {
			return unbindFirestoreRef('staffs')
		}),

		createStaff: async (store, { staff, password }) => {
			const createStaff = functions.httpsCallable('createStaff')
			const result = await createStaff({ staff, password })
			return result.data
		},

		updateStaff: async (store, { staff, password }) => {
			const updateStaff = functions.httpsCallable('updateStaff')
			const result = await updateStaff({ staff, password })
			return result.data
		},
	},
}
