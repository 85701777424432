import { firestoreAction } from 'vuexfire'
import { db, functions } from '@/firebase'

export const queueModule = {
	state: () => ({
		queues: [],
	}),

	getters: {
		queues: state => {
			return state.queues
		},
	},

	actions: {
		bindQueues: firestoreAction(async ({ bindFirestoreRef }, { shopId = null, startCounter = null, endCounter = null } = {}) => {
			let queryRef = db.collection('queues').orderBy('counter')
			if (shopId) queryRef = queryRef.where('shopId', '==', shopId)
			if (startCounter) queryRef = queryRef.where('counter', '>=', startCounter)
			if (endCounter) queryRef = queryRef.where('counter', '<=', endCounter)
			return bindFirestoreRef('queues', queryRef)
		}),

		unbindQueues: firestoreAction(async ({ unbindFirestoreRef }) => {
			return unbindFirestoreRef('queues')
		}),

		createQueue: async (store, { queue }) => {
			const createQueue = functions.httpsCallable('createQueue')
			const result = await createQueue({ queue })
			return result.data
		},

		updateQueueStatus: async (store, { queue, status }) => {
			const updateQueueStatus = functions.httpsCallable('updateQueueStatus')
			const result = await updateQueueStatus({ queue, status })
			return result.data
		},
	},
}
