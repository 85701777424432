import { Cart } from '@/models/cart'

export const cartModule = {
	state: () => ({
		cart: Cart(),
	}),

	getters: {
		cart: state => {
			return state.cart
		},
	},

	mutations: {
		'nube/SET_CART_METHOD': (state, { method }) => {
			state.cart.method = method
		},

		'nube/ADD_CART_PRODUCT': (state, { product }) => {
			state.cart.products.push(product)
		},

		'nube/UPDATE_CART_PRODUCT': (state, { index, product }) => {
			state.cart.products.splice(index, 1, product)
		},

		'nube/REMOVE_CART_PRODUCT': (state, { index }) => {
			state.cart.products.splice(index, 1)
		},

		'nube/UPDATE_TOTALS': state => {
			state.cart.productTotal = 0
			state.cart.products.forEach(product => (state.cart.productTotal += product.total))
			state.cart.productTotal = Math.round(state.cart.productTotal * 10) / 10
			state.cart.grandTotal = state.cart.productTotal
		},

		'nube/RESET_CART': state => {
			state.cart = Cart()
		},
	},

	actions: {
		setCartMethod: ({ commit }, { method }) => {
			commit('nube/SET_CART_METHOD', { method })
			return true
		},

		addCartProduct: ({ commit }, { product }) => {
			commit('nube/ADD_CART_PRODUCT', { product })
			commit('nube/UPDATE_TOTALS')
			return true
		},

		updateCartProduct: ({ commit }, { index, product }) => {
			commit('nube/UPDATE_CART_PRODUCT', { index, product })
			commit('nube/UPDATE_TOTALS')
			return true
		},

		removeCartProduct: ({ commit }, { index }) => {
			commit('nube/REMOVE_CART_PRODUCT', { index })
			commit('nube/UPDATE_TOTALS')
			return true
		},

		resetCart: ({ commit }) => {
			commit('nube/RESET_CART')
			return true
		},
	},
}
