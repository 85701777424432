import { firestoreAction } from 'vuexfire'
import { db, functions } from '@/firebase'
import { appType } from '../../../package.json'
import { registerPlugin } from '@capacitor/core'
import { Device } from '@capacitor/device'
import moment from 'moment'
const SunmiInnerPrinter = registerPlugin('SunmiInnerPrinter')

const saleMethods = { DINEIN: '堂食', PICKUP: '外賣自取', DELIVERY: '外賣速遞' }

export const printerModule = {
	state: () => ({
		printers: [],
	}),

	getters: {
		printers: state => {
			return state.printers
		},

		printer: state => id => {
			return state.printers.find(printer => printer.id === id)
		},
	},

	actions: {
		bindPrinters: firestoreAction(async ({ bindFirestoreRef }, { merchantId = null, shopId = null } = {}) => {
			let queryRef = db.collection('printers')
			if (merchantId) queryRef = queryRef.where('merchantId', '==', merchantId)
			if (shopId) queryRef = queryRef.where('shopId', '==', shopId)
			return bindFirestoreRef('printers', queryRef)
		}),

		unbindPrinters: firestoreAction(async ({ unbindFirestoreRef }) => {
			return unbindFirestoreRef('printers')
		}),

		createPrinter: async (store, { printer }) => {
			const createPrinter = functions.httpsCallable('createPrinter')
			const result = await createPrinter({ printer })
			return result.data
		},

		updatePrinter: async (store, { printer }) => {
			const updatePrinter = functions.httpsCallable('updatePrinter')
			const result = await updatePrinter({ printer })
			return result.data
		},

		printQueueTicket: async ({ state, getters }, { queue }) => {
			const printer = state.printers.find(printer => {
				if (!printer.appTypes.includes(appType)) return false
				if (!printer.ticketTypes.includes('QUEUE_TICKET')) return false
				return true
			})
			if (!printer) return true
			const { platform } = await Device.getInfo()
			if (printer.type === 'LAN') {
				const printQueueTicket = functions.httpsCallable('printQueueTicket')
				const result = await printQueueTicket({ printer, queue })
				return result.data
			} else if (platform === 'android' && printer.type === 'INTERNAL') {
				if (getters.currentMerchant.id !== queue.merchantId) throw { message: 'NubeTech Error: (queue/invalid-merchant-ID).' }
				if (getters.currentShop.id !== queue.shopId) throw { message: 'NubeTech Error: (queue/invalid-shop-ID).' }
				const tasks = []
				tasks.push({ type: 'TEXT', text: `${getters.currentMerchant.name} ${getters.currentShop.name}`, align: 1, size: 46 })
				tasks.push({ type: 'TEXT', text: `地址: ${getters.currentShop.businessAddress}`, align: 1, size: 23 })
				tasks.push({ type: 'TEXT', text: `電話: ${getters.currentShop.phoneNumber}`, align: 1, size: 23 })
				tasks.push({ type: 'TEXT', text: `取票時間: ${moment(queue.createdAt).format('YY-MM-DD HH:mm:ss')}`, align: 1, size: 23 })
				tasks.push({ type: 'TEXT', text: `${queue.referenceId}`, align: 1, size: 150 })
				tasks.push({ type: 'SPACE' })
				tasks.push({ type: 'SPACE' })
				tasks.push({ type: 'CUT' })
				return await SunmiInnerPrinter.print({ tasks })
			}
			return true
		},

		printKitchenTicket: async ({ state }, { saleOrder, product }) => {
			const printer = state.printers.find(printer => {
				if (printer.id === product.printerId) return true
				return false
			})
			if (!printer) return true
			const printKitchenTicket = functions.httpsCallable('printKitchenTicket')
			const result = await printKitchenTicket({ printer, saleOrder, product })
			return result.data
		},

		printKitchenTickets: async ({ state }, { saleOrder }) => {
			const saleOrderRef = db.collection('saleOrders').doc(saleOrder.id)
			for (let [index] of saleOrder.products.entries()) {
				await db.runTransaction(async transaction => {
					const saleOrderSnap = await transaction.get(saleOrderRef)
					const saleOrder = saleOrderSnap.data()
					if (saleOrder.products[index].status !== 'PROCESSING') return true
					const printer = state.printers.find(printer => {
						if (printer.id === saleOrder.products[index].printerId) return true
						return false
					})
					if (printer) {
						const printKitchenTicket = functions.httpsCallable('printKitchenTicket')
						await printKitchenTicket({ printer, saleOrder, product: saleOrder.products[index] })
					}
					saleOrder.products[index].status = 'CLOSED'
					transaction.update(saleOrderRef, saleOrder)
					return saleOrder
				})
			}
			return true
		},

		printOrderTicket: async ({ state }, { saleOrder }) => {
			const printer = state.printers.find(printer => {
				if (!printer.appTypes.includes(appType)) return false
				if (!printer.ticketTypes.includes('ORDER_TICKET')) return false
				return true
			})
			if (!printer) return true
			const printOrderTicket = functions.httpsCallable('printOrderTicket')
			const result = await printOrderTicket({ printer, saleOrder })
			return result.data
		},

		printExpediteTicket: async ({ state }, { saleOrder }) => {
			const printer = state.printers.find(printer => {
				if (!printer.appTypes.includes(appType)) return false
				if (!printer.ticketTypes.includes('EXPEDITE_TICKET')) return false
				return true
			})
			if (!printer) return true
			const printExpediteTicket = functions.httpsCallable('printExpediteTicket')
			const result = await printExpediteTicket({ printer, saleOrder })
			return result.data
		},

		printReceipt: async ({ state }, { saleOrder }) => {
			const printer = state.printers.find(printer => {
				if (!printer.appTypes.includes(appType)) return false
				if (!printer.ticketTypes.includes('RECEIPT')) return false
				return true
			})
			if (!printer) return true
			const { platform } = await Device.getInfo()
			if (printer.type === 'LAN') {
				const printReceipt = functions.httpsCallable('printReceipt')
				const result = await printReceipt({ printer, saleOrder })
				return result.data
			} else if (platform === 'android' && printer.type === 'INTERNAL') {
				const tasks = []
				tasks.push({ type: 'SPACE', height: 2 })
				tasks.push({ type: 'COLUMN', texts: [`${saleOrder.method === 'DINEIN' ? saleOrder.tables[0].name : 'S' + saleOrder.referenceId.substr(-3)}`, `${saleMethods[saleOrder.method]}`], widths: [7, 8], aligns: [0, 2], size: 74 })
				tasks.push({ type: 'SPACE' })
				tasks.push({ type: 'TEXT', text: `${saleOrder.merchant.name} ${saleOrder.shop.name}`, align: 1, size: 44 })
				tasks.push({ type: 'TEXT', text: `地址: ${saleOrder.shop.businessAddress}`, align: 1 })
				tasks.push({ type: 'TEXT', text: `電話: ${saleOrder.shop.phoneNumber}`, align: 1 })
				tasks.push({ type: 'DIVIDER', lines: 2 })

				tasks.push({ type: 'TEXT', text: `點餐時間: ${moment(saleOrder.createdAt).format('YY-MM-DD HH:mm:ss')}` })
				tasks.push({ type: 'TEXT', text: `收據編號: ${saleOrder.referenceId}` })
				tasks.push({ type: 'TEXT', text: `訂單編號: S${saleOrder.referenceId.substr(-3)} ${saleMethods[saleOrder.method]}` })
				tasks.push({ type: 'TEXT', text: `人數: ${saleOrder.pax}` })
				tasks.push({ type: 'DIVIDER', lines: 2 })

				tasks.push({ type: 'TEXT', text: `商品內容:` })
				saleOrder.products.forEach(product => {
					tasks.push({ type: 'COLUMN', texts: [product.quantity, `${product.name}`, `$${Math.round(product.total * 10) / 10}`], widths: [12, 14, 14], aligns: [0, 0, 2] })
					product.items.forEach(item => {
						tasks.push({ type: 'COLUMN', texts: ['', ` ${item.name} ${item.price[saleOrder.method] > 0 ? `(+$${item.price[saleOrder.method]})` : ``}`], widths: [14, 26], aligns: [0, 0] })
					})
				})
				tasks.push({ type: 'DIVIDER', lines: 1 })

				tasks.push({ type: 'COLUMN', texts: ['小計', `$${Math.round(saleOrder.productTotal * 10) / 10}`], widths: [20, 20], aligns: [0, 2] })
				tasks.push({ type: 'DIVIDER', lines: 1 })

				tasks.push({ type: 'COLUMN', texts: ['總額', `$${Math.round(saleOrder.grandTotal * 10) / 10}`], widths: [9, 9], aligns: [0, 2], size: 62 })
				tasks.push({ type: 'COLUMN', texts: [`已付 (${saleOrder.payments[0].type})`, `$${Math.round(saleOrder.payments[0].total * 10) / 10}`], widths: [20, 20], aligns: [0, 2] })
				if (saleOrder.paymentTotal - saleOrder.grandTotal > 0) tasks.push({ type: 'COLUMN', texts: ['找續', `$${Math.round((saleOrder.paymentTotal - saleOrder.grandTotal) * 10) / 10}`], widths: [20, 20], aligns: [0, 2] })
				tasks.push({ type: 'DIVIDER', lines: 2 })

				tasks.push({ type: 'SPACE' })
				tasks.push({ type: 'TEXT', text: '謝謝光臨', align: 1 })
				tasks.push({ type: 'SPACE' })
				tasks.push({ type: 'QRCODE', text: `${saleOrder.id}`, size: 10 })
				tasks.push({ type: 'SPACE' })
				tasks.push({ type: 'TEXT', text: 'Powered by NubeTech', align: 1 })
				tasks.push({ type: 'SPACE' })
				tasks.push({ type: 'CUT' })
				return SunmiInnerPrinter.print({ tasks })
			}
			return true
		},

		printBusinessReport: async ({ state, getters }, { businessReport }) => {
			const printer = state.printers.find(printer => {
				if (!printer.appTypes.includes(appType)) return false
				if (!printer.ticketTypes.includes('BUSINESS_REPORT')) return false
				return true
			})
			if (!printer) return true
			const { platform } = await Device.getInfo()
			if (printer.type === 'LAN') {
				const printBusinessReport = functions.httpsCallable('printBusinessReport')
				const result = await printBusinessReport({ printer, businessReport })
				return result.data
			} else if (platform === 'android' && printer.type === 'INTERNAL') {
				const tasks = []
				tasks.push({ type: 'TEXT', text: `${getters.currentMerchant.name} ${getters.currentShop.name}`, align: 1, size: 44 })
				tasks.push({ type: 'SPACE' })
				tasks.push({ type: 'TEXT', text: '日結報告', align: 1 })
				tasks.push({ type: 'DIVIDER', lines: 2 })
				tasks.push({ type: 'TEXT', text: `建立日期: ${moment().format('YY-MM-DD HH:mm:ss')}` })
				tasks.push({ type: 'DIVIDER', lines: 2 })

				tasks.push({ type: 'TEXT', text: '銷售方式' })
				businessReport.methods.forEach(method => {
					tasks.push({ type: 'COLUMN', texts: ['', `-${method.name}`, method.count, `$${Math.round(method.total * 10) / 10}`], widths: [2, 18, 5, 14], aligns: [0, 0, 2, 2] })
				})
				tasks.push({ type: 'SPACE' })

				tasks.push({ type: 'TEXT', text: '付款方式' })
				businessReport.payments.forEach(payment => {
					tasks.push({ type: 'COLUMN', texts: ['', `-${payment.name}`, payment.count, `$${Math.round(payment.total * 10) / 10}`], widths: [2, 18, 5, 14], aligns: [0, 0, 2, 2] })
				})
				tasks.push({ type: 'SPACE' })

				tasks.push({ type: 'TEXT', text: '時段分析' })
				businessReport.periods.forEach(period => {
					tasks.push({ type: 'COLUMN', texts: ['', `-${period.name}`, period.count, `$${Math.round(period.total * 10) / 10}`], widths: [2, 18, 5, 14], aligns: [0, 0, 2, 2] })
				})
				tasks.push({ type: 'SPACE' })

				tasks.push({ type: 'TEXT', text: '商品分析' })
				businessReport.products.forEach(product => {
					tasks.push({ type: 'COLUMN', texts: ['', `-${product.name}`, product.count ? product.count : 0, `$${Math.round((product.total ? product.total : 0) * 10) / 10}`], widths: [2, 18, 5, 14], aligns: [0, 0, 2, 2] })
				})
				tasks.push({ type: 'SPACE' })

				tasks.push({ type: 'CUT' })
				await SunmiInnerPrinter.print({ tasks })
			}
			return true
		},
	},
}
