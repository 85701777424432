<template>
    <div id="app" class="bg-black h-screen overflow-hidden">
        <router-view />
        <n-alert v-if="$store.getters.showLoading" hiddenFooter="true" title="系統訊息" content="截入中..."></n-alert>
        <!-- <n-alert v-if="$store.getters.showLoading" hiddenFooter="true" :title="alert.title" :content="alert.message"></n-alert> -->
    </div>
</template>

<script>
import NAlert from '@/components/Alert.vue'
import { v4 as uuidv4 } from 'uuid'
import { appType } from '../package.json'
import moment from 'moment'

export default {
    components: {
        NAlert,
    },
    data() {
        return {
			device: '',
            currentDate: moment().utcOffset(8).format('YYMMDD'),
        }
    },

    watch: {
        async currentDate() {
            const startCounter = Number(moment().utcOffset(8).format('YYMMDD') + '000')
            const endCounter = Number(moment().utcOffset(8).format('YYMMDD') + '999')
            await this.$store.dispatch('bindQueues', { shopId: this.device.shopId, status: ['CALL'], startCounter: startCounter, endCounter: endCounter })
            await this.$store.dispatch('bindSaleOrders', { shopId: this.device.shopId, startCounter: startCounter, endCounter: endCounter })
            this.$store.dispatch('setNewDay', { status: true })
            this.$store.dispatch('setNewDay', { status: false })
        },
    },

    async created() {
        try {
            if (!localStorage.getItem('deviceId')) localStorage.setItem('deviceId', uuidv4())
            const deviceId = localStorage.getItem('deviceId')
			this.device = await this.$store.dispatch('bindCurrentDevice', { id: deviceId })
            const device = await this.$store.dispatch('bindCurrentDevice', { id: deviceId })
            if (!device || !device.licenses.find(l => l.appType === appType) || device.status !== 'ACTIVE') {
                this.$store.dispatch('setLoading', { status: false })
                if (this.$route.path === '/activate') return
                return this.$router.push('/activate')
            }
            const startCounter = Number(moment().utcOffset(8).format('YYMMDD') + '000')
            const endCounter = Number(moment().utcOffset(8).format('YYMMDD') + '999')
            await this.$store.dispatch('bindCurrentMerchant', { id: device.merchantId })
            await this.$store.dispatch('bindCurrentShop', { id: device.shopId })
            await this.$store.dispatch('bindQueues', { shopId: device.shopId, status: ['CALL'], startCounter: startCounter, endCounter: endCounter })
            await this.$store.dispatch('bindSaleOrders', { shopId: device.shopId, startCounter: startCounter, endCounter: endCounter })
            setInterval(async () => {
                const date = moment().utcOffset(8).format('YYMMDD')
                if (this.currentDate === date) return
                this.currentDate = date
            }, 1000)
            this.$store.dispatch('setLoading', { status: false })
            if (this.$route.path === '/signage') return
            return this.$router.push('/signage')
        } catch (e) {
            console.error(e)
            this.$store.dispatch('setError', { errorMessage: e.message, status: true })
            this.$store.dispatch('setLoading', { status: false })
        }
    },
}
</script>
